export const ROUTE_HOME = {
  path: '/',
  name: 'home',
};

export const ROUTE_ABOUT = {
  path: '/about',
  name: 'about',
};

export const ROUTE_VISITANTES = {
  path: '/visitantes',
  name: 'visitantes',
};

export const ROUTE_VISITANTES_ID = {
  path: '/fila/:filaId',
  name: 'fila.id',
};

export const ROUTE_RECEBIMENTO = {
  path: '/recebimento',
  name: 'recebimento',
};

export const ROUTE_RESUMO_ID = {
  path: '/resumo/:id',
  name: 'resumo.id',
};

export const ROUTE_PRODUCAO = {
  path: '/producao',
  name: 'produção',
};

export const ROUTE_FILA = {
  path: '/fila',
  name: 'fila',
};

export const ROUTE_FILA_ID = {
  path: '/fila/:filaId',
  name: 'fila.id',
};

export const ROUTE_RECEBIMENTO_ID = {
  path: '/recebimento/:recebimentoId',
  name: 'recebimento.id',
};

export const ROUTE_APONTAMENTO_PESAGEM = {
  path: '/producao-apontamento',
  name: 'producao-apontamento',
};

export const ROUTE_RECEBIMENTO_RESUMO = {
  path: '/recebimento/resumo',
  name: 'recebimento.resumo',
};

export const ROUTE_IMGLOTE = {
  path: '/imagens/lote',
  name: 'imglote',
};

export const ROUTE_IMGCONTRATO = {
  path: '/imagens/contratomp',
  name: 'imgcontratomp',
};

export const ROUTE_IMGINVOICE = {
  path: '/imagens/invoice',
  name: 'imginvoice',
};

export const ROUTE_IMGOS = {
  path: '/imagens/ordemservico',
  name: 'imgordemservico',
};

export const ROUTE_ESTOQUE = {
  path: '/estoque',
  name: 'estoque',
};

export const ROUTE_CONSULTA = {
  path: '/consulta',
  name: 'consulta',
};

export const ROUTE_CADASTRO = {
  path: '/cadastro',
  name: 'cadastro',
};

export const ROUTE_IMAGENS = {
  path: '/imagens',
  name: 'imagens',
};

export const ROUTE_CAMERA = {
  path: '/camera/:tipo:id:valor',
  name: 'camera',
};

export const ROUTE_QR = {
  path: '/qr/:tipo',
  name: 'qr',
};

export const ROUTE_LOTES = {
  path: '/lotes',
  name: 'lotes',
};

export const ROUTE_LOTES_ID = {
  path: '/lotes/:lotesid',
  name: 'lotes-id',
};

export const ROUTE_SIGN_IN = {
  path: '/sing-in',
  name: 'sign-in',
};

export const ROUTE_FIN_PAGAR = {
  path: '/apagar',
  name: 'apagar',
};

export const ROUTE_FIN_RECEBER = {
  path: '/areceber',
  name: 'areceber',
};
